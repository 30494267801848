import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledRegularAssetComponent = styled.div`
  width: 100%;

  .asset-component-wrapper {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
    margin: auto;
    display: flex;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.full-width {
      max-width: none;
    }

    .regular-asset-image-wrapper {
      .regular-asset-image {
        width: 100% !important;
        height: auto;
      }
    }

    video {
      display: block !important;
    }
  }
`;
